<template>
<div class="caseSign">
  <div class="search">
    <div>
      病例模板: &nbsp;<a-select v-model="searchValue.selectId" style="width: 220px;" >
<!--      <a-select-option  v-for="list in caseTemplates" :value="list.id">-->
<!--        <span :title="list.title">{{list.title}}</span>-->
<!--      </a-select-option>-->
      <a-select-option   :value="24">
        <span title="比伐芦定在冠心病行PCI治疗患者中应用的真实世界研究">比伐芦定在冠心病行PCI治疗患者中应用的真实世界研究</span>
      </a-select-option>
    </a-select>
    </div>
    <div style="margin-left: 5%">
      上传时间: &nbsp;<a-range-picker @change="dateChange" v-model="searchValue.date"  valueFormat="YYYY-MM-DD" style="width: 220px" />
    </div>
    <div style="margin-left: 5%">
      <a-button style="margin-right: 10px" type="primary" @click="()=>{this.getCase()}">查询</a-button>
      <a-button @click="()=>{searchValue.selectId=24;searchValue.date=[];this.getCase()}">重置</a-button>
    </div>
  </div>

  <div class="caseList">

    <a-card hoverable
            v-for="list in caseData" :key="list.id"
            @click="case_btn(list)">
      <img :src="list.signatureImageList[0]" style="object-fit: cover; width: 100%; height: 200px" alt="">
    </a-card>

    <a-modal
        title=""
        :visible="visible"
        :footer="null"
        width="70%"
        @cancel="()=>{this.visible=false}"
    >
      <div style="margin-top: 20px">
        <div style="display: flex;justify-content: space-between;width: 60%;margin: 1%">
          <div>医生姓名:{{ doctor_info.name }}</div>
          <div>手机号:{{ doctor_info.tel }}</div>
          <div>执业医师证号:{{ doctor_info.doctorNum }}</div>
        </div>
        <div style="display: flex;justify-content: space-between;width: 60%;margin: 1%">
          <div>医院名称:{{ doctor_info.hospitalName }}</div>
          <div>科室名称:{{ doctor_info.hospitalOffices }}</div>
          <div>医生职称:{{ doctor_info.jobTitle }}</div>
        </div>
      </div>
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">
        <div style="width: 30%;height: 30%;margin: 1%;cursor: pointer" v-for="list in caseImg_index.signatureImageList" :key="list">
          <img :src="list" style="object-fit: cover; width: 100%; height: 300px" alt="">
        </div>
      </div>
    </a-modal>


  </div>
</div>
</template>

<script>
import {getCaseSigns, getCaseTemplates, memberInfo} from "@/service/CaseTemplate_api";

export default {
  name: "caseSign",
  data(){
    return{
      visible:false,
      caseTemplates:[],
      caseImg_index:{},
      doctor_info:{},
      caseData:[],
      searchValue:{
        selectId:24,
        date:[],
      },
    }
  },
  mounted() {
    this.$store.dispatch('setManageHeaderTitle', "病历签字管理")
    // this.getCaseAccountList()
    this.getCase()
  },
  methods:{
    // 获取病例模板
    async getCaseAccountList(){
      const result = await getCaseTemplates()
      if (result.code === 0){
        this.caseTemplates = result.data.rows
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
    },
    async getCase(){
      const data={
        templateId:this.searchValue.selectId,
        startTime:this.searchValue.date[0],
        endTime:this.searchValue.date[1],
        status:'SIGNED',
      }
      const info = await getCaseSigns(data)
      if (info.code===0){
        this.caseData=info.data
      }else {
        this.$message.warn('获取失败'+info.message)
      }
      this.$forceUpdate()
    },

    async case_btn(row){
      const member = await memberInfo(row.memberId)
      this.doctor_info=member.data
      this.caseImg_index=row
      this.visible=true
    },
    dateChange(date, dateString){
      // this.searchValue.date[0] = dateString[0]
      // this.searchValue.date[1] = dateString[1]
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-card{
  width: 18%;
  height: 200px;
  margin: 1%;
  position: relative;
}
::v-deep .ant-card-body{
  padding: 0 ;
}

.caseSign{
  margin: -15px -15px;
  min-height: 110%;
  background-color:rgba(240, 242, 245, 1);
  .search{
    border-radius: 3px;
    background-color: white;
    height: 70px;
    width: 100%;
    margin: 0 auto;
    line-height: 70px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .caseList{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px auto;
    ::v-deep .ant-card{
      width: 18%;
      height: 200px;
      margin: 1%;
      position: relative;
    }
    ::v-deep .ant-card-body{
      padding: 0 ;
    }
  }
}
</style>
